import type {
	BreakpointsData,
	Component,
	ClassVariant,
	HoverVariant,
	Structure,
	PresetVariant,
	LayoutDataItems,
	SingleLayoutData,
	BreakpointRange,
	BaseDataItem,
} from '@wix/thunderbolt-becky-types'
import { constants } from '@wix/thunderbolt-becky-root'
import { MegaStore, VariantRelationViewDataItem } from '@wix/thunderbolt-catharsis'
import type {
	BreakpointsToSelectorObj,
	BreakpointsVariantsToSelectorsMap,
	ComponentCss,
	CompVariants,
	ResponsiveLayoutResult,
	SelectorObj,
} from '../types'

const { PINNED_LAYER_SUFFIX } = constants
const VARIANTS_SEPARATOR = '$$$'
export const toVariantsString = (variants: Array<string>): string => variants.join(VARIANTS_SEPARATOR)
export const fromVariantsString = (variants: string): Array<string> => variants.split(VARIANTS_SEPARATOR)

export const isSingleLayoutData = (item: LayoutDataItems): item is SingleLayoutData => item.type === 'SingleLayoutData'
export const isVariantRelation = <T extends BaseDataItem>(
	item: VariantRelationViewDataItem<T> | T
): item is VariantRelationViewDataItem<T> => item.type === 'VariantRelation'

const VARIANT_SELECTOR: Record<string, (variant: any) => string> = {
	Hover: (variant: HoverVariant) => `#${variant.componentId}:hover`,
	Class: (variant: ClassVariant) => `#${variant.componentId}.${variant.id}`,
	Preset: (variant: PresetVariant) => `.${variant.id}`,
	Mobile: () => '.device-mobile-optimized',
}

const getRegularIdSelector = (compId: string) => `#${compId}`
const getTemplateRepeaterIdSelector = (compId: string) => `[id^="${compId}__"]`
const addLayoutSelectorType = (
	compId: string,
	selector: string,
	layoutSelectorType: string = '',
	shouldOmitWrapperLayers: boolean = false
): string => {
	switch (layoutSelectorType) {
		case 'component::before':
			return `${selector}::before`
		case 'component-one-cell-grid':
			return `${selector}:not(.${compId}-container)`
		case 'component':
		case 'item':
			return selector
		default:
			if (shouldOmitWrapperLayers) {
				if (selector === getRegularIdSelector(compId)) {
					return `.${compId}-${layoutSelectorType}`
				}
			} else {
				return `${selector} .${compId}-${layoutSelectorType}`
			}
			return selector
	}
}

const getVariantSelector = (variantId: string, variants: CompVariants) => {
	if (!variantId) {
		return ''
	}
	const variant = variants[variantId]
	return VARIANT_SELECTOR[variant.type](variant)
}

const responsiveLayoutDomSelector = (
	compId: string,
	idSelector: string,
	variantSelector: string,
	selector: string,
	shouldOmitWrapperLayers: boolean
) => {
	const hasVariant = variantSelector
	const sameComponentVariant = variantSelector && variantSelector.startsWith(idSelector)
	const compSelector = addLayoutSelectorType(
		compId,
		sameComponentVariant ? variantSelector : idSelector,
		selector,
		shouldOmitWrapperLayers
	)
	const domSelector = hasVariant && !sameComponentVariant ? `${variantSelector} ${compSelector}` : compSelector
	return domSelector
}

const pinnedLayerDomSelector = (idSelector: string) => `${idSelector}${PINNED_LAYER_SUFFIX}`

export const toMediaQuery = (item: BreakpointRange) => {
	const min = item.min ? ` and (min-width: ${item.min}px)` : ''
	const max = item.max ? ` and (max-width: ${item.max}px)` : ''
	return `@media screen${min}${max}`
}

export const selectorObjToCss = (selectorObj: SelectorObj) =>
	Object.entries(selectorObj)
		.flatMap(
			([selector, css]) =>
				`${selector}{${Object.entries(css)
					.map(([key, value]) => `${key}:${value};`)
					.join('')}}`
		)
		.join('')

export const getComponentResponsiveLayoutCss = (
	compId: string,
	isInRepeater: boolean,
	compResponsiveLayout: ResponsiveLayoutResult,
	compPinnedLayer: BreakpointsVariantsToSelectorsMap | null,
	compVariants: CompVariants,
	compBreakpointsOrder: BreakpointsData | null
): BreakpointsToSelectorObj => {
	const idSelector = isInRepeater ? getTemplateRepeaterIdSelector(compId) : getRegularIdSelector(compId)

	return ['default' as const, ...(compBreakpointsOrder?.values || [])].reduce<BreakpointsToSelectorObj>(
		(acc, breakpointRangeItem) => {
			const breakpointId = breakpointRangeItem === 'default' ? 'default' : breakpointRangeItem.id
			acc[breakpointId] = acc[breakpointId] || {}

			const responsiveLayoutInBreakpoint = compResponsiveLayout?.css[breakpointId]
			const pinnedLayerInBreakpoint = compPinnedLayer?.[breakpointId]

			for (const variantKey in responsiveLayoutInBreakpoint) {
				const variantSelector = getVariantSelector(variantKey, compVariants)
				const selectorObj = responsiveLayoutInBreakpoint[variantKey]
				for (const selector in selectorObj) {
					const domSelector = responsiveLayoutDomSelector(
						compId,
						idSelector,
						variantSelector,
						selector,
						!!compResponsiveLayout?.shouldOmitWrapperLayers
					)
					acc[breakpointId][domSelector] = acc[breakpointId][domSelector] || {}
					Object.assign(acc[breakpointId][domSelector], selectorObj[selector])
				}
			}

			for (const variantKey in pinnedLayerInBreakpoint) {
				const selectorObj = pinnedLayerInBreakpoint[variantKey]
				for (const selector in selectorObj) {
					const domSelector = pinnedLayerDomSelector(idSelector)
					acc[breakpointId][domSelector] = acc[domSelector] || {}
					Object.assign(acc[breakpointId][domSelector], selectorObj[selector])
				}
			}

			return acc
		},
		{}
	)
}

export const getComponentsResponsiveLayoutCss = (
	megaStore: MegaStore,
	structure: Structure,
	pageCompId: string
): BreakpointsToSelectorObj => {
	const cssStore = megaStore.getChildStore('componentCss')

	const result: BreakpointsToSelectorObj = { default: {} }
	const mediaQueries: { [breakpointId: string]: string } = { default: 'default' }

	const traverseComponents = (
		comp: Component,
		isInRepeater: boolean,
		ancestorComponentBreakpointsOrder: BreakpointsData | null
	) => {
		const compId = comp.id
		const {
			breakpointsOrder = ancestorComponentBreakpointsOrder,
			pinnedLayer,
			responsiveLayout,
			variants,
		} = cssStore.getById<ComponentCss>(compId)
		const myLayout = getComponentResponsiveLayoutCss(
			compId,
			isInRepeater,
			responsiveLayout,
			pinnedLayer,
			variants,
			breakpointsOrder
		)

		if (breakpointsOrder?.values) {
			for (const breakpointRangeItem of breakpointsOrder?.values) {
				if (!mediaQueries[breakpointRangeItem.id]) {
					const mediaQuery = toMediaQuery(breakpointRangeItem)
					mediaQueries[breakpointRangeItem.id] = mediaQuery
					result[mediaQuery] = result[mediaQuery] || {}
				}
			}
		}

		for (const breakpointId in myLayout) {
			Object.assign(result[mediaQueries[breakpointId]], myLayout[breakpointId])
		}

		comp.components?.forEach((childId) =>
			traverseComponents(structure[childId], isInRepeater || comp.type === 'RepeaterContainer', breakpointsOrder)
		)
	}

	traverseComponents(structure[pageCompId], false, null)

	return result
}

export const serializeToString = (mediaQueriesToSelectorObj: BreakpointsToSelectorObj) => {
	return Object.entries(mediaQueriesToSelectorObj).reduce<string>((acc, [mediaQuery, breakpointCss]) => {
		const currentBreakpointCss = selectorObjToCss(breakpointCss)
		return mediaQuery === 'default'
			? `${acc}${currentBreakpointCss}`
			: `${acc}${mediaQuery}{${currentBreakpointCss}}`
	}, '')
}
